<template>
  <div class="contenedor">
    <div
      class="logo-info"
    >
    <router-link to="/">
      <Logo logoWidth= "30vw" data-aos="flip-up"/></router-link>
      <div class="info">
        <h4 class="h4-contacto" data-aos="flip-up">CONTACTO</h4>
        <a class="svg-p" href="https://maps.app.goo.gl/xqXQSytJ338Hkswa9" target="_blank">
          <svg data-aos="flip-up"
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.74914 13.0069L8.14168 19.0727L13.5342 13.0069C17.6702 8.35451 14.3671 1 8.14168 1C1.91625 1 -1.38681 8.35451 2.74914 13.0069Z"
              stroke="#8A8A8A"
              stroke-width="1.65062"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.14169 10.9404C9.63889 10.9404 10.8526 9.72665 10.8526 8.22946C10.8526 6.73227 9.63889 5.51855 8.14169 5.51855C6.6445 5.51855 5.43079 6.73227 5.43079 8.22946C5.43079 9.72665 6.6445 10.9404 8.14169 10.9404Z"
              stroke="#8A8A8A"
              stroke-width="1.65062"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="a-contacto" data-aos="flip-up">Mitre 629, Resistencia Chaco.</p>
        </a>
        <a class="svg-p" href="mailto:ventas.saboresmitre@gmail.com">
          <svg data-aos="flip-up"
            width="21"
            height="17"
            viewBox="0 0 21 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.83569 1.31543H17.5207C18.5303 1.31543 19.3563 2.14146 19.3563 3.15106V14.1648C19.3563 15.1744 18.5303 16.0004 17.5207 16.0004H2.83569C1.82609 16.0004 1.00006 15.1744 1.00006 14.1648V3.15106C1.00006 2.14146 1.82609 1.31543 2.83569 1.31543Z"
              stroke="#8A8A8A"
              stroke-width="1.65"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.3563 3.15137L10.1781 9.57606L1 3.15137"
              stroke="#8A8A8A"
              stroke-width="1.65"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="a-contacto" data-aos="flip-up">ventas.saboresmitre@gmail.com</p>
        </a>
        <a class="svg-p" href="https://wa.me/+543624894354?text=Hola%21%20Quiero%20consultar%20por..." target="_blank">
          <svg data-aos="flip-up"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.24573 1L5.45673 1.1162C6.13306 1.14066 6.73326 1.56776 6.99247 2.209L7.94191 4.55776C8.16288 5.10434 8.10341 5.72797 7.78341 6.22029L6.56698 8.0919C7.28718 9.12611 9.24614 11.5492 11.3204 12.9676L12.8671 12.0157C13.2601 11.7738 13.7308 11.702 14.1756 11.816L17.2503 12.6044C18.0681 12.8142 18.6191 13.5984 18.5545 14.4605L18.3571 17.0928C18.2878 18.0165 17.5318 18.7414 16.6343 18.6371C4.78828 17.2596 -2.15848 0.999974 2.24573 1V1Z"
              stroke="#8A8A8A"
              stroke-width="1.65"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="a-contacto" data-aos="flip-up">3624 894354</p>
        </a>
      </div>
    </div>
    <div class="infoAlmoby">
      <p>@ Todos los derechos reservados. Desarrollado por</p>
      <img class="imgAlmoby" src="../assets/almoby.png" alt="" />
    </div>
  </div>
</template>
<script>
import Logo from "@/components/Logo.vue";
export default {
  name: "PieDePagina",
  components: {
    Logo,
  },
};
</script>
<style scoped>
.logo-info {
  display: flex;
  justify-content: space-between;
  padding: 4rem;
}

.a-contacto {
  text-decoration: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 35px;
  color: #040303;
}

.svg-p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
}

.h4-contacto {
  font-family: "Cormorant Garamond";
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1em;
  border-bottom: solid 1px #4c2d1c;
  padding-bottom: 0.5rem;
  margin-bottom: 1.2rem;
}

.infoAlmoby {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  margin-bottom: 2rem;
}

.infoAlmoby p{
  margin: 0;
}


.displayNone {
  display: none;
}

@media screen and (max-width: 800px){
  .logo-info{
    flex-direction: column;
  }
  .h4-contacto{
    text-align: center;
  }
  .infoAlmoby{
    flex-direction: column
  }
  .infoAlmoby p{
    font-size: 2vw;
  }

  .a-contacto{
    font-size: 2.5vw;
  }
}
</style>
