import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AOS from 'aos';
import 'aos/dist/aos.css';


createApp(App).use(store).use(router).mount('#app')
AOS.init({
    duration: 1000, // Establece la duración global a 2000ms (2 segundos)
  });