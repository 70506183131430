import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";

const routes = [
  {
    path: "/",
    name: "inicio",
    component: () =>
      import(/* webpackChunkName: "Inicio" */ "../views/Inicio.vue"),
  },
  {
    path: "/iniciar-sesion",
    name: "iniciarSesion",
    component: () =>
      import(
        /* webpackChunkName: "Iniciar Sesion" */ "../views/IniciarSesion.vue"
      ),
  },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/admin/PuntoEntradaAdmin.vue"
      ),
    children: [
      {
        path: "", // Ruta vacía, actuará como el path por defecto para `/admin`
        redirect: "/admin/menu", // Redirige automáticamente a `/admin/menu`
      },
      {
        path: "menu",
        name: "menu",
        component: () =>
          import(/* webpackChunkName: "menu" */ "../views/admin/Menu.vue"),
      },
      {
        path: "ver-eventos-programados",
        name: "verEventosProgramados",
        component: () =>
          import(
            /* webpackChunkName: "verEventosProgramados" */ "../views/admin/VerEventosProgramados.vue"
          ),
      },
      {
        path: "borrar-editar-eventos",
        name: "borrarEditarEventos",
        component: () =>
          import(
            /* webpackChunkName: "borrarEditarEventos" */ "../views/admin/BorrarEditarEventos.vue"
          ),
      },
      {
        path: "agregar-eventos",
        name: "agregarEventos",
        component: () =>
          import(
            /* webpackChunkName: "agregarEventos" */ "../views/admin/AgregarEventos.vue"
          ),
      },
    ],
    meta: { requiresAuth: true }, // Marca que esta ruta requiere autenticación
  },
  {
    path: "/nuestros-eventos",
    name: "nuestrosEventos",
    component: () =>
      import(
        /* webpackChunkName: "NuestrosEventos" */ "../views/NuestrosEventos.vue"
      ),
  },
  {
    path: "/catalogo",
    name: "catalogo",
    component: () =>
      import(/* webpackChunkName: "catalogo" */ "../views/Catalogo.vue"),
  },
  {
    path: "/catalogo/vinos",
    name: "vinos",
    component: () =>
      import(/* webpackChunkName: "vinos" */ "../views/Vinos.vue"),
  },
  {
    path: "/catalogo/whiskies",
    name: "whiskies",
    component: () =>
      import(/* webpackChunkName: "whiskies" */ "../views/Whiskies.vue"),
  },
  {
    path: "/catalogo/cocteleria",
    name: "cocteleria",
    component: () =>
      import(/* webpackChunkName: "cocteleria" */ "../views/Cocteleria.vue"),
  },
  {
    path: "/catalogo/aderezos-aceites-y-mas",
    name: "aderezosaceitesymas",
    component: () =>
      import(
        /* webpackChunkName: "aderezosaceitesymas" */ "../views/AderezosAceitesyMas.vue"
      ),
  },
  {
    path: "/catalogo/cognac-grappas",
    name: "cognacGrapas",
    component: () =>
      import(/* webpackChunkName: "cognac" */ "../views/CognacGrappas.vue"),
  },
  {
    path: "/catalogo/tabacos-cigarros",
    name: "tabacosCigarros",
    component: () =>
      import(/* webpackChunkName: "tabaco" */ "../views/TabacosCigarros.vue"),
  },
  {
    path: "/catalogo/chocolates",
    name: "chocolates",
    component: () =>
      import(/* webpackChunkName: "chocolates" */ "../views/Chocolates.vue"),
  },
  {
    path: "/catalogo/cristaleria",
    name: "cristaleria",
    component: () =>
      import(/* webpackChunkName: "cristaleria" */ "../views/Cristaleria.vue"),
  },
  {
    path: "/:catchAll(.*)", // Esta ruta coincidirá con cualquier ruta no definida
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "chocolates" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
          resolve();
        }, 400);
      });
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0); 
          resolve();
        }, 400); 
      });
    }
  },
});

 router.beforeEach((to, from, next) => {
   const isAuthenticated = Cookies.get('token') !== undefined; // Verifica si hay un token

   // Si la ruta requiere autenticación y el usuario no está autenticado
   if (to.matched.some(record => record.meta.requiresAuth)) {
     if (!isAuthenticated) {
       next({ path: '/iniciar-sesion' }); // Redirigir a la página de inicio de sesión
     } else {
       next(); // Permitir acceso a rutas privadas
     }
   } else {
     next(); // Permitir acceso a rutas públicas
   }
 });

export default router;
