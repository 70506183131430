<template>
  <div class="contenedor">
    <img :style="{ width: logoWidth, height: logoHeight }" src="../assets/logo.png" alt="" />
  </div>
</template>
<script>
export default {
  name: "Logo",
  props: {
    logoWidth: {
      type: String,
      default: "315px", 
    },
    logoHeight: {
      type: String,
      default: "160px", 
    },
  },
};
</script>
<style scoped>
.contenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
img{
  object-fit: contain;
}
</style>
