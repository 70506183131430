<template>
  <div class="contenedor">
    <div class="logo-nav" :class="{ 'logo-nav-admin': esAdmin }">
      <router-link to="/">
        <Logo logoWidth="16vw" logoHeight="auto" data-aos="fade-right"
      /></router-link>
      <nav class="nav" :class="{ displayNone: esAdmin }">
        <router-link to="/" active-class="active-link">Inicio</router-link>
        <router-link :to="{ path: '/', hash: '#sobre-nosotros' }"
          >Sobre nosotros</router-link
        >
        <router-link to="/catalogo" active-class="active-link"
          >Catálogo</router-link
        >
        <router-link to="/nuestros-eventos" active-class="active-link"
          >Eventos</router-link
        >
        <router-link :to="{ path: '/', hash: '#contacto' }"
          >Contacto</router-link
        >
      </nav>
      <!-- Menu Hamburguesa -->
      <div class="contenedorMenuHabmburguesa" :class="{ displayNone: esAdmin }">
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            class="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </button>

        <div
          ref="offcanvas"
          class="offcanvas offcanvas-end"
          style="width: 300px"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasRightLabel">MENÚ</h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <router-link
              class="routerLink"
              to="/"
              @click.prevent="closeOffcanvas({ path: '/' })"
              >Inicio</router-link
            >
            <router-link
              class="routerLink"
              :to="{ path: '/', hash: '#sobre-nosotros' }"
              @click.prevent="
                closeOffcanvas({ path: '/', hash: '#sobre-nosotros' })
              "
              >Sobre nosotros</router-link
            >
            <router-link
              class="routerLink"
              to="/catalogo"
              @click.prevent="closeOffcanvas({ path: '/catalogo' })"
              >Catálogo</router-link
            >
            <router-link
              class="routerLink"
              to="/nuestros-eventos"
              @click.prevent="closeOffcanvas({ path: '/nuestros-eventos' })"
              >Eventos</router-link
            >
            <router-link
              class="routerLink"
              :to="{ path: '/', hash: '#contacto' }"
              @click.prevent="closeOffcanvas({ path: '/', hash: '#contacto' })"
              >Contacto</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from "@/components/Logo.vue";
export default {
  name: "BarraNavegacion",
  components: {
    Logo,
  },

  computed: {
    esAdmin() {
      return (
        this.$route.path === "/iniciar-sesion" ||
        this.$route.path === "/:catchAll(.*)" ||
        this.$route.path.startsWith("/admin")
      );
    },
  },

  mounted() {
    this.offcanvas = new bootstrap.Offcanvas(
      document.getElementById("offcanvasRight")
    );
  },

  methods: {
    closeOffcanvas(route) {
      this.offcanvas.hide();
      setTimeout(() => {
        this.$router.push(route);
      }, 300);
    },
  },
};
</script>

<style>
.logo-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem;
}

.logo-nav-admin {
  justify-content: center;
  background-color: #ffffff;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 65px;
}

nav a,
.routerLink {
  font-family: "Cormorant Upright";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
  text-decoration: none;
  padding: 0.4vw;
}

.nav a:hover,
.active-link {
  color: white;
  background: #9a9797;
  border-radius: 1.5vw;
}

.active-link {
  font-weight: 800;
}

.displayNone {
  display: none;
}

.offcanvas-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.routerLink {
  color: #ffffff;
}

.offcanvas {
  background: #050505dd;
}

.contenedorMenuHabmburguesa {
  display: none;
}

/* ajustes por pantalla */

@media screen and (max-width: 1000px) {
  .nav {
    display: none;
  }
  .contenedorMenuHabmburguesa {
    display: flex;
  }
}
</style>
